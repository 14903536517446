import React from 'react';

import styled from 'styled-components';
import { Link } from 'gatsby';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

const Inner = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding-top: 400px;
    padding-bottom: 400px;
  }
`;

const SubmitSuccessPage: React.FC = () => (
  <Layout>
    <SEO title="Submission Success" />
    <Inner>
      <h1 className="is-size-1">Thank you for your submission.</h1>
      <Link to="/">
        <h3 className="is-size-3">Go back home</h3>
      </Link>
    </Inner>
  </Layout>
);

export default SubmitSuccessPage;
